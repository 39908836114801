<template>
  <div class="content-box">
    <div class="box-shadow">
      <div class="clearfix add-box">
        <el-button class="pull-left" type="success" size="medium" @click="addItem()">新增</el-button>
      </div>
    </div>
    <div style="padding: 25px 0;">
      <el-table :data="dataLists" :header-cell-style="{background: '#eee', color: '#333'}" style="width: 100%;" highlight-current-row stripe v-loading="loading" >
        <el-table-column label="角色" prop="name" align="center" min-width="20%"></el-table-column>
        <el-table-column label="CODE" prop="code" align="center" min-width="30%"></el-table-column>
        <el-table-column label="操作" align="center" min-width="50%">
          <template slot-scope="scope">
            <!-- <el-button size="mini" type="primary" plain @click="editItem(scope.row)">编辑</el-button> -->
            <el-button size="mini" type="danger" plain  @click="setPower(scope.row.id)">设置权限</el-button>
            <el-button size="mini" type="success" plain  @click="delItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="PaginationFun"
      @current-change="PaginationFun"
      :current-page.sync="pageNo"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="totalCount"
      background>
    </el-pagination>
    <!-- 编辑弹窗 -->
    <el-dialog title="角色" :visible="dialogVisible" width="30%" :show-close="false">
      <el-form
        ref="dataItem"
        :model="dataItem"
        class="demo-form-inline"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="名称：" prop="name">
          <el-input v-model="dataItem.name"></el-input>
        </el-form-item>
        <el-form-item label="CODE：" prop="code">
          <el-input v-model="dataItem.code"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native.prevent="clearSubmit()">取 消</el-button>
        <el-button type="primary" @click.native.prevent="submit()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置权限弹窗 -->
    <el-dialog title="设置权限" :visible="dialogVisibleAuth" width="30%" :show-close="false">
      <el-tree
        ref="tree"
        :data="authTree"
        show-checkbox
        node-key="id"
        default-expand-all
        :check-strictly="isCheck"
        v-loading="treeLoading"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ data.name }}</span>
        </span>
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native.prevent="clearAuth()">取 消</el-button>
        <el-button type="primary" @click.native.prevent="submitAuth()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  AdminGetRoleList,
  AdminGetRoleItem,
  AdminAddRole,
  AdminEditRole,
  AdminDelRole,
  AdminGetDirectoryTree,
  AdminGetRoleMenu,
  AdminUpdateRoleMenu
} from '@/api/adminApi'
export default {
  name: 'SettingRole',
  data () {
    return {
      dialogVisible: false,
      dialogVisibleAuth: false,
      isCheck: false,
      loading: false,
      treeLoading: false,
      totalPage: 0, // 总页数
      totalCount: 0, // 总条数
      pageNo: 1, // 当前页数
      pageSize: 10, // 每页几条
      pattern: 'add',
      authId: '', // 设置目录的角色id
      authTree: [], // 目录树
      menuIds: [], // 选中的目录id
      dataLists: [],
      dataItem: {
        id: '',
        name: '',
        code: ''
      },
      searchName: '',
      orderBy: 'updateDate',
      rules: {
        name: [{ required: true, message: '必填', trigger: 'blur' }],
        code: [{ required: true, message: '必填', trigger: 'blur' }]
      }
    }
  },
  components: {},
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      AdminGetRoleList({
        name: this.searchName,
        pageInfo: {
          orderBy: this.orderBy,
          pageNo: this.pageNo,
          pageSize: this.pageSize
        }
      }).then((resp) => {
        this.loading = false
        if (resp.code === '000') {
          this.totalCount = resp.result.count
          this.totalPage = Math.ceil(resp.result.count / this.pageSize)
          this.pageNo = resp.result.pageNo
          this.dataLists = [...resp.result.list]
        } else {
          this.$message({ message: resp.msg, type: 'error' })
          this.dataLists = []
        }
      })
    },
    getItem (id) {
      AdminGetRoleItem({ id: id }).then((resp) => {
        if (resp.code === '000') {
          this.dataItem.id = resp.result.id
          this.dataItem.name = resp.result.name
          this.dataItem.code = resp.result.code
          this.dialogVisible = true
        } else {
          this.$message({ message: resp.msg, type: 'error' })
        }
      })
    },
    editItem (id) {
      this.pattern = 'edit'
      this.getItem(id)
    },
    delItem (id) {
      this.$confirm('此操作将删除该目录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        AdminDelRole({ id: id }).then((resp) => {
          this.resultFun(resp)
        })
      }).catch()
    },
    addItem () {
      this.pattern = 'add'
      this.dataItem.id = ''
      this.dataItem.name = ''
      this.dataItem.code = ''
      this.dialogVisible = true
    },
    clearSubmit () {
      this.dialogVisible = false
    },
    submit () {
      this.$refs.dataItem.validate((valid) => {
        if (valid) {
          if (this.pattern === 'add') {
            delete this.dataItem.id
            AdminAddRole(this.dataItem).then((resp) => {
              this.resultFun(resp)
            })
          } else if (this.pattern === 'edit') {
            AdminEditRole(this.dataItem).then((resp) => {
              this.resultFun(resp)
            })
          }
        } else {
        }
      })
    },
    setPower (id) {
      this.authId = id
      this.isCheck = true
      this.dialogVisibleAuth = true
      this.treeLoading = true
      if (this.authTree.length === 0) {
        AdminGetDirectoryTree({}).then((resp) => {
          this.authTree = [...resp.result]
        })
      }
      this.$nextTick(() => {
        AdminGetRoleMenu({ id: id }).then((resp) => {
          this.treeLoading = false
          this.menuIds = [...resp.result]
          this.$refs.tree.setCheckedKeys(this.menuIds)
          this.isCheck = false
        })
      })
    },
    clearAuth () {
      this.menuIds = []
      this.$refs.tree.setCheckedKeys(this.menuIds)
      this.dialogVisibleAuth = false
    },
    submitAuth () {
      this.menuIds = [
        ...this.$refs.tree.getCheckedKeys(),
        ...this.$refs.tree.getHalfCheckedKeys()
      ]
      AdminUpdateRoleMenu({
        id: this.authId,
        menuIds: this.menuIds.join(',')
      }).then((resp) => {
        if (resp.code === '000') {
          this.$message({ message: '操作成功', type: 'success' })
          this.dialogVisibleAuth = false
        } else {
          this.$message({ message: resp.msg, type: 'error' })
        }
      })
    },
    PaginationFun (val) {
      this.pageNo = val
      this.getList()
    },
    resultFun (resp) {
      if (resp.code === '000') {
        this.$message({ message: '操作成功', type: 'success' })
        this.dialogVisible = false
        this.getList()
      } else {
        this.$message({ message: resp.msg, type: 'error' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
</style>
